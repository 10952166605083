// components/RouteChangeListener.js
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { paginationService } from "../state/pagination";
import { tokenService } from "../state/token";
import axios from "../api";
import { API, encryptStorage } from "../helpers";

const RouteChangeListener = () => {
  const location = useLocation();

  async function checkVersion() {
    const currentVersion = localStorage.getItem("appVersion");
    const newVersion =
      typeof __APP_VERSION__ !== "undefined"
        ? __APP_VERSION__ // Production build
        : import.meta.env.VITE_APP_VERSION; // Development mode
    if (currentVersion && currentVersion !== newVersion) {
      // Notify user or reload the page
      console.log("New version detected, reloading...");
      window.location.reload();
    }

    // Update stored version
    localStorage.setItem("appVersion", newVersion);
  }

  async function getProfile({ controller = new AbortController() }) {
    const signal = controller.signal;
    await axios
      .get(`${API.sso}/auth/profile`, {
        signal,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        let data = res?.data?.data;
        const { roles, ...rest } = data;
        encryptStorage.setItem("presensiRoles", roles || []);
        encryptStorage.setItem("presensiUser", rest);
      })
      .catch((err) => {
        console.log({ errProfile: err });
      });
  }

  useEffect(() => {
    // You can perform any other logic here, such as triggering side effects
    paginationService.setPagination(null);
    tokenService.setToken();
    const controller = new AbortController();
    checkVersion();
    const check = ["auth", "login"].includes(location?.pathname);
    if (!check) {
      getProfile({ controller });
    }
  }, [location, getProfile]); // Dependency on location to detect route changes

  return <></>; // This component doesn't render anything, just listens for route changes
};

export default RouteChangeListener;
