import { lazy } from "react";
import { encryptStorage } from "./helpers";
import { Navigate } from "react-router-dom";

const DashboardPage = lazy(() => import("./pages/dashboard"));
const WorkingTimePage = lazy(() => import("./pages/working-time"));
const SchedulePage = lazy(() => import("./pages/schedule"));
const CalendarPage = lazy(() => import("./pages/calendar"));
const MasterWorkerTypePage = lazy(() => import("./pages/master/workerType"));
const MasterOvertimePage = lazy(() => import("./pages/master/overtime"));
const MasterShiftPage = lazy(() => import("./pages/master/shift"));
const StaffPage = lazy(() => import("./pages/staff/index"));
const OvertimePage = lazy(() => import("./pages/overtime"));
const ShiftSchedulePage = lazy(() => import("./pages/shiftSchedule"));
const AttendancePage = lazy(() => import("./pages/attendance"));
const MasterUnitPage = lazy(() => import("./pages/master/unit"));
const ProfilePage = lazy(() => import("./pages/profile"));
const ReportPage = lazy(() => import("./pages/report"));

const Authorized = ({ component, permission }) => {
  const permissions =
    encryptStorage
      .getItem("presensiRoles")
      ?.find((data) => data?.appCode === "presensi")?.permissions || [];
  const { permissions: permissions2, is_custom } =
    encryptStorage.getItem("presensiUser");
  const auth = parseInt(is_custom || 0)
    ? permissions2?.includes(permission)
    : permissions?.includes(permission);
  if (!auth) {
    return <Navigate to="/401" />;
  }
  return component;
};

const RouteList = [
  {
    path: "/",
    name: "Beranda",
    element: <DashboardPage />,
  },
  {
    path: "/beranda",
    name: "Beranda",
    element: <DashboardPage />,
  },
  {
    path: "/kalender",
    name: "Kalender",
    element: (
      <Authorized component={<CalendarPage />} permission="view_calendar" />
    ),
  },
  {
    path: "/pegawai",
    name: "Pegawai",
    element: <Authorized component={<StaffPage />} permission="view_staff" />,
  },
  {
    path: "/lembur",
    name: "Lembur",
    element: (
      <Authorized component={<OvertimePage />} permission="view_overtime" />
    ),
  },
  {
    path: "/laporan",
    name: "Laporan",
    element: <ReportPage />,
  },
  {
    path: "/jam-kerja",
    name: "Jam Kerja",
    element: (
      <Authorized
        component={<WorkingTimePage />}
        permission="view_working_hours"
      />
    ),
  },
  {
    path: "/jadwal-kerja",
    name: "Jadwal Kerja",
    element: (
      <Authorized component={<SchedulePage />} permission="view_schedule" />
    ),
  },
  {
    path: "/jam-kerja-shift",
    name: "Jam Kerja Shift",
    element: (
      <Authorized
        component={<MasterShiftPage />}
        permission="view_shift_work_hours"
      />
    ),
  },
  {
    path: "/jadwal-kerja-shift",
    name: "Jadwal Kerja Shift",
    element: (
      <Authorized
        component={<ShiftSchedulePage />}
        permission="view_shift_schedule"
      />
    ),
  },
  {
    path: "/jenis-pekerjaan",
    name: "Jenis Pekerjaan",
    element: (
      <Authorized
        component={<MasterWorkerTypePage />}
        permission="view_worker_type"
      />
    ),
  },
  {
    path: "/ketentuan-lembur",
    name: "Ketentuan Lembur",
    element: (
      <Authorized
        component={<MasterOvertimePage />}
        permission="view_master_overtime"
      />
    ),
  },
  {
    path: "/kehadiran",
    name: "Kehadiran",
    element: (
      <Authorized component={<AttendancePage />} permission="view_attendance" />
    ),
  },
  {
    path: "/unit",
    name: "Unit",
    element: (
      <Authorized component={<MasterUnitPage />} permission="view_unit" />
    ),
  },
  {
    path: "/profile",
    name: "Pengaturan Akun",
    element: <ProfilePage />,
  },
];

export default RouteList;
