import { BehaviorSubject } from "rxjs"
import Cookies from 'js-cookie'
import { encryptStorage } from "../helpers"
const tokenSubject = new BehaviorSubject(null)

export const tokenService = {
  token$: tokenSubject.asObservable(),
  setToken: () => {
    const token = {
      access: Cookies.get("presensi_access_token"),
      refresh: Cookies.get("presensi_refresh_token")
    }
    tokenSubject.next(token)
  }
}