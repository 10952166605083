import { Navigate, useRoutes } from "react-router-dom";
import { lazy } from "react";
import RouteList from "./RouteList";
import Cookies from "js-cookie";
const LoginPage = lazy(() => import("./pages/login"));
const Unauthorized = lazy(() => import("./pages/401"));
const Layout = lazy(() => import("./layouts"));
const AuthUser = lazy(() => import("./AuthUser"));
const QrCodePage = lazy(() => import("./pages/qrcode"));
const QrCodeRemover = lazy(() => import("./pages/qrcode/remove"));

const Authenticated = ({ component }) => {
  const auth = Cookies.get("presensi_access_token");

  if (!auth) {
    return <Navigate to="/login" />;
  }

  return component;
};

export default function Router() {
  const routes = useRoutes([
    {
      path: "/",
      element: <Authenticated component={<Layout />} />,
      name: "Beranda",
      children: RouteList,
    },
    {
      path: "/auth",
      element: <AuthUser />,
      name: "Auth",
    },
    {
      path: "/401",
      name: "Unauthorized",
      element: <Unauthorized />,
    },
    {
      path: "/qr",
      name: "QrCode Generator",
      element: <QrCodePage />,
    },
    {
      path: "/qr/remove",
      name: "QrCode Remove Generator",
      element: <QrCodeRemover />,
    },
    {
      path: "login",
      element: <LoginPage />,
    },
    {
      path: "*",
      element: <div>Not Found</div>,
    },
  ]);

  return routes;
}
